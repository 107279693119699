import React from "react";

function CartMessage() {
  return (
    <div className="bc-cart-error">
      <p className="bc-cart-error__message"></p>
    </div>
  );
}

export default CartMessage;
