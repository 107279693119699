export const emailRegExp = /^[^A-Z|'|']*$/;
export const zipcodeRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export function getFormError(errors, name) {
  return (errors && errors[name] && errors[name]) || "";
}

export function emailRegMsg() {
    return `Email should not include capital lettter and '"`
}

export function zipcodeRegMsg() {
  return `please enter valid zip code`
}

export function requiredEmailMsg() {
  return `Please enter a valid email address`;
}

export function requiredMsg(name = "field") {
  return `Please enter a ${name}`;
}

export function requiredSelectMsg(name = "field") {
  return `Please enter a ${name}`;
}
