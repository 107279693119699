import React from 'react'
import PropTypes from "prop-types";

function ErrorMessage({ children }) {
  return <span className='form-error-message'>{children}</span>;
}

ErrorMessage.protoType = {
  children: PropTypes.string,
};

export default ErrorMessage