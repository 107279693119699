import { useFormik } from "formik";
import React from "react";
import { getFormError } from "../../utils/form";
import TextField from "../form/TextField";
import useToggle from "../../hooks/useToggle";

function AddCertificate() {
  const [form, toggleForm] = useToggle(false);

  const formik = useFormik({
    initialValues: {
      gift_certificate: "",
    },
    onSubmit: (code) => {
      console.log("code", code);
    },
  });

  return (
    <>
      <div className="col">
        <strong>Gift Certificate:</strong>
      </div>
      <div className="col align-right">
        <button
          className="button button--secondary button-small"
          onClick={toggleForm}
        >
          {form ? "Cancel" : "Gift Certificate"}
        </button>
      </div>
      {form && (
        <form onSubmit={formik.handleSubmit} className="cart-from">
          <div className="form-row form-row--half">
            <div className="form-field">
              <TextField
                id={"customer-gift-certificate"}
                name={"gift_certificate"}
                labelFor={"postal-code"}
                type={"input"}
                placeholder={"Add certificate"}
                onChange={formik.handleChange}
                value={formik.values.gift_certificate}
                error={getFormError(formik.errors, "gift_certificate")}
              />
            </div>
            <div className="form-field form-button">
              <button className="button" type="submit">
                Apply
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default AddCertificate;
