import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CartContext } from "../../context/CartContext";
import { useFormik } from "formik";
import Loader from "../form/Loader";
import { UPDATE_CART_TIMEOUT } from "../../settings/cart";

function AdjustItem({ cartType, item }) {
  const { updateCartItem, updatingItem, removeCartItem } =
    useContext(CartContext);
  const [state, setState] = useState({ quantity: item.quantity, flag: false });
  const [timer, setTimer] = useState(null);

  // set timer on state change / quantity change via buttons ...
  useEffect(() => {
    if (state.quantity !== item.quantity && state.flag) {
      setUpdateTimer();
    }
  }, [state]);

  // update quantity on tab select realtime ...
  useEffect(async () => {
    setState(() => {
      return { flag: false, quantity: item.quantity };
    });
    await formik.setFieldValue("quantity", item.quantity);
  }, [item]);

  const formik = useFormik({
    initialValues: {
      quantity: item.quantity,
    },
  });

  // timer for calling update api ...
  const setUpdateTimer = () => {
    // check if any timer already exists if yes then discard previous one and create new one ...
    if (timer) {
      clearTimeout(timer);
    }
    const timeOut = setTimeout(() => {
      // remove product from cart when quantity is zero otherwise update line-item..
      if (state.quantity === 0) {
        removeCartItem(item);
      } else {
        updateCartItem(item, parseInt(state.quantity), formik);
      }
    }, UPDATE_CART_TIMEOUT);
    setTimer(timeOut);
  };

  // update increment or decrement state quantity via buttons ...
  const handleQuantityChange = async (value) => {
    setState((prevState) => {
      return { flag: true, quantity: prevState.quantity + value };
    });
    await formik.setFieldValue("quantity", state.quantity + value);
  };

  return (
    <div className="bc-cart-item-quantity">
      <div className="form-increment">
        {cartType === "full" && (
          <button className="button" onClick={() => handleQuantityChange(-1)}>
            -
          </button>
        )}

        <form onSubmit={formik.handleSubmit}>
          {/* particular any particular line item updating show loader there.. */}
          {updatingItem === item.sku ? (
            <Loader />
          ) : (
            <input
              type="number"
              name="quantity"
              id={item.sku}
              onWheel={(e) => e.target.blur()}
              className="form-input qty-input"
              onChange={formik.handleChange}
              value={formik.values.quantity}
              // while focus removed from the input box immediatly
              // update cart line item api call will trigger ...
              onBlur={(e) => {
                parseInt(e.target.value) !== state.quantity &&
                  updateCartItem(item, parseInt(e.target.value), formik).then(
                    (res) => {
                      // update local state if line-item updated successfully...
                      if (res) {
                        setState(() => {
                          return {
                            flag: false,
                            quantity: parseInt(e.target.value),
                          };
                        });
                      }
                    }
                  );
              }}
            />
          )}
        </form>

        {cartType === "full" && (
          <button className="button" onClick={() => handleQuantityChange(1)}>
            +
          </button>
        )}
      </div>
      <small className="cart-invertory-count">{`${item.inventory} Left`}</small>
    </div>
  );
}

AdjustItem.propTypes = {
  item: PropTypes.object,
  cartType: PropTypes.string,
};

export default AdjustItem;
