import React from "react";

function CartHeader() {
  return (
    <header className="bc-cart-header">
      <div className="bc-cart-header__item">Item</div>
      <div className="bc-cart-header__qty">Qty</div>
      <div className="bc-cart-header__price">Price</div>
    </header>
  );
}

export default CartHeader;
