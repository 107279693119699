import React, { useContext } from "react";
import PropTypes from "prop-types";
import CouponCode from "./CouponCode";
import AddCertificate from "./AddCertificate";
import CurrencyFormatter from "../cart/CurrencyFormatter";
import { CartContext } from "../../context/CartContext";

function CartFooter({ cartType, cart, lineItemsLength }) {
  const { clearCart, checkoutCart } = useContext(CartContext);

  // render footer only when cart type is 'full' ...
  if (cartType === "full") {
    return (
      <footer className="bc-cart-footer">
        <div className="cart-totals">
          <ul className="cart-total">
            <li>
              <div className="col">
                <strong>{`SubTotal:`}</strong>
              </div>
              <div className="col align-right">
                <span>
                  <CurrencyFormatter
                    amount={cart?.["base_amount"] || 0}
                    currency={cart.currency.code}
                  />
                </span>
              </div>
            </li>

            <li>
              <CouponCode cartCoupons={cart["coupons"]} />
            </li>
            <li>
              <AddCertificate />
            </li>

            <li className="cart-grand-total">
              <div className="col">
                <strong>{`Grand Total:`}</strong>
              </div>
              <div className="col align-right grand-total-price">
                <strong>
                  <CurrencyFormatter
                    amount={cart?.["cart_amount"] || 0}
                    currency={cart.currency.code}
                  />
                </strong>
              </div>
            </li>
          </ul>
        </div>

        {lineItemsLength > 0 && (
          <div className="bc-cart-actions text-right">
            <button
              className="bc-btn bc-cart-actions__checkout-button button button--secondary"
              onClick={() => clearCart()}
            >
              Clear Cart
            </button>
            <button
              className="bc-btn bc-cart-actions__checkout-button button"
              onClick={() => checkoutCart(cart.checkout_url)}
            >
              Proceed to Checkout
            </button>
          </div>
        )}
      </footer>
    );
  } else <></>;
}

CartFooter.propTypes = {
  cartType: PropTypes.string,
  cart: PropTypes.object,
  lineItemsLength: PropTypes.number,
};

export default CartFooter;
