const CurrencyFormatter = ({ currency, amount }) => {
  const languageCode = 'en-US';
  const formattedPrice = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency
  }).format(amount);
  return amount && formattedPrice;
};

export default CurrencyFormatter;
