import React from "react";
import Layout from "../layout/Layout";
import Cart from "../sections/cart/Cart";

function CartPageTemplate() {
  return (
    <>
      <div className="page-heading-section text-center page-block">
        <h1 className="page-title">Your Cart</h1>
      </div>
      <section className="container">
        <Cart cartType="full" />
      </section>
    </>
  );
}

function cartPage() {
  return (
    <Layout>
      <CartPageTemplate />
    </Layout>
  );
}

export default cartPage;
