import React from "react";
import { Link } from "gatsby";
import useCart from "../../hooks/cart/useCart";
import PropTypes from "prop-types";
import CartFooter from "../../components/cart/CartFooter";
import StandardItems from "../../components/cart/StandardItems";
import CartHeader from "../../components/cart/CartHeader";
import CartMessage from "../../components/cart/CartMessage";
import Loader from "../../components/form/Loader";
import { useSelector } from "react-redux";
import Button from "../../components/form/button/Button";

const Cart = ({ cartType }) => {
  const { cart, lineItems, isLoading, lineItemsLength } = useCart();
  const { user } = useSelector((state) => state.auth);

  return (
    <section className="bc-cart">
      <CartMessage />
      <CartHeader />
      {user && isLoading ? (
        <Loader />
      ) : lineItemsLength > 0 ? (
        <div className="bc-cart-body">
          <StandardItems lineItems={lineItems} cartType={cartType} />
          <CartFooter
            cartType={cartType}
            cart={cart}
            lineItemsLength={lineItemsLength}
          />
        </div>
      ) : !user ? (
        <div className="bc-cart__empty">
          <p className="bc-cart__title--empty">
            Login to see the items you added previously.
          </p>
          <Button to="/login">Login</Button>
        </div>
      ) : (
        <div className="bc-cart__empty">
          <h4 className="bc-cart__title--empty">Your cart is empty.</h4>
          <Link to="/products" className="bc-cart__continue-shopping link">
            Take a look around.
          </Link>
        </div>
      )}
    </section>
  );
};

Cart.propTypes = {
  cartType: PropTypes.string,
};

export default Cart;
