import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import AdjustItem from "./AdjustItem";
import { formatPrice } from "../../utils/money";
import { CartContext } from "../../context/CartContext";

function StandardItems({ lineItems, cartType }) {
  const { removeCartItem } = useContext(CartContext);

  return (
    <>
      {lineItems.map((item) => {
        return (
          <div className="bc-cart-item" key={item.variant_id}>
            {cartType === "full" ? (
              <div className="bc-cart-item-image">
                <div className="cart-img-inner">
                  <img src={item.image_url} alt={`${item.name}`} />
                </div>
                <button
                  className="bc-link bc-cart-item__remove-button"
                  onClick={() => removeCartItem(item)}
                  type="button"
                >
                  &nbsp;
                </button>
              </div>
            ) : (
              <></>
            )}

            <div className="bc-cart-item-meta">
              <Link to={`/products${item.url}`}>
                <h3 className="bc-cart-item__product-title">{item.name}</h3>
              </Link>
              <span className="bc-cart-item__product-brand">{item.sku}</span>
            </div>

            <AdjustItem item={item} cartType={cartType} />

            <div className="bc-cart-item-total-price">
              {formatPrice(item.list_price || item.price)}
            </div>
          </div>
        );
      })}
    </>
  );
}

StandardItems.propTypes = {
  lineItems: PropTypes.array,
  cartType: PropTypes.string,
};

export default StandardItems;
