export function parseCurrencyString(str) {
  const parsedStr = str || str === 0 ? str.toString().replace(/\D/g, "") : "";

  return parsedStr;
}

export function formatInputNumber(n, symbol = "$") {
  const strNumber = n.toString();
  const isNegative = strNumber.includes("-");
  const hasDecimal = strNumber.includes(".");
  const fullNumber = hasDecimal ? strNumber.split(".")[0] : n;
  const decimal = hasDecimal ? strNumber.split(".")[1] : "";

  const res = parseCurrencyString(fullNumber)
    .replace("-", "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const negativeRes = isNegative ? `-${symbol}${res}` : symbol + res;

  return `${negativeRes}${hasDecimal ? `.${decimal}` : ".00"}`;
}
export function formatPrice(n = "") {
  return isNaN(!n) ? "-" : formatInputNumber(n);
}

export function calculatePrices(sale_price, price, retail_price) {
  let result = {
    defaultPrice: {
      value: 0,
      class: "",
    },
    retailPrice: {
      value: 0,
      class: "",
    },
    salePrice: {
      value: 0,
      class: "",
    },
  };

  // Case - 1: Show only sale price when sale price is greater than all ...
  if (sale_price !== 0 && sale_price >= price && sale_price >= retail_price) {
    result["salePrice"]["value"] = sale_price;
  }

  // Case - 2: Show retail price and sale price when retail price is greater than sale price ...
  if (sale_price !== 0 && sale_price >= price && sale_price < retail_price) {
    result["retailPrice"] = {
      value: retail_price,
      class: "strike-price",
    };
    result["salePrice"]["value"] = sale_price;
  }

  // Case - 3: Show default price and sale price when retail price is zero...
  if (sale_price !== 0 && sale_price < price && sale_price > retail_price) {
    result["defaultPrice"] = {
      value: price,
      class: "strike-price",
    };
    result["salePrice"]["value"] = sale_price;
  }

  // Case - 4: Show all price when retail price and default price is greater than sale price ...
  if (sale_price !== 0 && sale_price < price && sale_price < retail_price) {
    result["defaultPrice"] = {
      value: price,
      class: "strike-price",
    };
    result["retailPrice"] = {
      value: retail_price,
      class: "strike-price",
    };
    result["salePrice"]["value"] = sale_price;
  }

  // Case - 5: When default price is greater than retail price ...
  if (sale_price === 0 && price >= retail_price) {
    result["defaultPrice"] = {
      value: price,
      class: "current-price",
    };
  }

  // Case - 6: When default price is less than retail price ...
  if (sale_price === 0 && price < retail_price) {
    result["retailPrice"] = {
      value: retail_price,
      class: "strike-price",
    };
    result["defaultPrice"] = {
      value: price,
      class: "current-price",
    };
  }

  return result;
}
