import { useFormik } from "formik";
import React, { useContext } from "react";
import useToggle from "../../hooks/useToggle";
import TextField from "../../components/form/TextField";
import { getFormError } from "../../utils/form";
import { CartContext } from "../../context/CartContext";
import propTypes from "prop-types";
import { formatPrice } from "../../utils/money";

const initialValues = {
  coupon_code: "",
};

function CouponCode({ cartCoupons }) {
  const [couponCode, toggleCouponCodeForm] = useToggle(false);
  const { applyCouponCode, removeCouponCode } = useContext(CartContext);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (code, { resetForm }) => {
      applyCouponCode(code["coupon_code"]);
      resetForm(initialValues);
    },
  });

  return (
    <>
      {cartCoupons?.length ? (
        cartCoupons.map((coupon) => (
          <div key={coupon.id} className="flex coupon-code-wrap">
            <div className="col flex-wrap">
              <strong>{`Coupon (${coupon.code})`}</strong>
              <div className="coupon-remove-wrap">
              <p className="remove-coupon-link" onClick={() => removeCouponCode(coupon.code)}>Remove</p>
              </div>
            </div>
            <div className="col align-right">
              <p>{formatPrice(coupon.discounted_amount * -1)}</p>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="col">
            <strong>{"Coupon Code:"}</strong>
          </div>
          <div className="col align-right">
            <button
              className="button button--secondary button-small"
              onClick={toggleCouponCodeForm}
            >
              {couponCode ? "Cancel" : "Add Coupon"}
            </button>
          </div>
          {couponCode && (
            <form onSubmit={formik.handleSubmit} className="cart-from">
              <div className="form-row form-row--half">
                <div className="form-field">
                  <TextField
                    id={"customer-coupon-code"}
                    name={"coupon_code"}
                    labelFor={"coupon-code"}
                    type={"input"}
                    placeholder={"Enter your coupon code"}
                    onChange={formik.handleChange}
                    value={formik.values.coupon_code}
                    error={getFormError(formik.errors, "coupon_code")}
                  />
                </div>
                <div className="form-field form-button">
                  <button className="button" type="submit">
                    Apply
                  </button>
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
}

CouponCode.propTypes = {
  cartCoupons: propTypes.oneOfType([propTypes.string, propTypes.array]),
};
export default CouponCode;
